export default [
    {
        name: 'environmentMapTexture',
        type: 'cubeTexture',
        path:
        [
            'textures/environmentMaps/office/px.png',
            'textures/environmentMaps/office/nx.png',
            'textures/environmentMaps/office/py.png',
            'textures/environmentMaps/office/ny.png',
            'textures/environmentMaps/office/pz.png',
            'textures/environmentMaps/office/nz.png'
        ]
    },
    {
        name: 'avatarModel',
        type: 'gltfModel',
        path: 'https://api.readyplayer.me/v1/avatars/645a78116977bf73b0dbf27a.glb' 
    },
    {
        name: 'avatarAnimationIdle',
        type: 'fbxModel',
        path: 'models/animations/idle.fbx' 
    },
    {
        name: 'avatarAnimationTalking',
        type: 'fbxModel',
        path: 'models/animations/idle2.fbx' 
    },
    {
        name: 'avatarAnimationHello',
        type: 'fbxModel',
        path: 'models/animations/hello.fbx' 
    },
    {
        name: 'avatarAnimationThank',
        type: 'fbxModel',
        path: 'models/animations/thank.fbx' 
    },
    {
        name: 'avatarAnimationSitting',
        type: 'fbxModel',
        path: 'models/animations/sitting.fbx' 
    },
    {
        name: 'avatarAnimationAngry',
        type: 'fbxModel',
        path: 'models/animations/angry.fbx' 
    },
    {
        name: 'grassColorTexture',
        type: 'texture',
        path: 'textures/floor/stone/color.jpg' 
    },
    {
        name: 'grassNormalTexture',
        type: 'texture',
        path: 'textures/floor/stone/normal.jpg' 
    }


]