import * as THREE from 'three'
import Experience from "../Experience";
import { gsap } from "gsap";


export default class Avatar
{
    constructor()
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.debug = this.experience.debug

        // Debug
        if(this.debug.active)
        {
            this.debugFolder = this.debug.ui.addFolder('Avatar')
        }
    
        // Setup
        this.avatar = this.resources.items.avatarModel

        this.setModel()
        this.setAnimation()
        this.playEyesBlink()
        this.playAnimation()
        this.setMouthAnimation()

        this.listenerAnimations()
        this.listenerAudio()
        this.listenerVideo()


    }

    setModel()
    {
        
        this.model = this.avatar.scene
        this.model.rotation.y = 0.14

        this.model.traverse((object) => {
            if (object.isBone) {
              if (object.name === 'LeftEye') {
                this.model.leftEyeBone = object;
              }
              if (object.name === 'RightEye') {
                this.model.rightEyeBone = object;
              }
            }
        });

        this.scene.add(this.model)

        this.model.traverse((child) => {
            if(child instanceof THREE.Mesh)
            {
                child.castShadow = true
            }
        })
    }

    setAnimation()
    {
        this.animation = {}
        this.animation.mixer = new THREE.AnimationMixer(this.model)
        this.animation.actions = {}
        this.animationSources = [
            'avatarAnimationIdle', 
            'avatarAnimationTalking',
            'avatarAnimationHello',
            'avatarAnimationThank',
            'avatarAnimationSitting',
            'avatarAnimationAngry',
        ]

        for (let filename of this.animationSources) {
            const animationFile = this.resources.items[filename]
            const animationName = filename;

            for (let i = 0; i < animationFile.animations.length; i++) {
                animationFile.animations[i].name = animationName
                this.animation.actions[animationName] = this.animation.mixer.clipAction(animationFile.animations[i])
            }
        }
        
        this.animation.actions.current = this.animation.actions[this.animationSources[0]];
        this.animation.actions.current.play();
    }

    playEyesBlink()
    {
        const blinksPerMinute = 17; // Valore medio tra 15 e 20
        const averageBlinkInterval = (60 / blinksPerMinute) * 1000;

        const blinkIntervalVariance = 0.3; // Variabilità dell'intervallo di tempo (30%)
        const minBlinkInterval = averageBlinkInterval * (1 - blinkIntervalVariance);
        const maxBlinkInterval = averageBlinkInterval * (1 + blinkIntervalVariance);
      
        this.model.animateEyes = (open) =>
        {
            if (!this.model.leftEyeBone || !this.model.rightEyeBone) {
              console.error('Eye bones not found.');
              return;
            }
          
            const targetRotation = open ? 0 : 0.8; // Chiusura degli occhi
          
            this.model.leftEyeBone.rotation.x = targetRotation;
            this.model.rightEyeBone.rotation.x = targetRotation;
        }

        setTimeout(() => {
          // Chiudi gli occhi
          this.model.animateEyes(false);
      
          setTimeout(() => {
            // Apri gli occhi
            this.model.animateEyes(true);
      
            // Continua a lampeggiare gli occhi casualmente
            this.playEyesBlink();
          }, 200); // Durata del lampeggiamento (ms)
        }, Math.random() * (maxBlinkInterval - minBlinkInterval) + minBlinkInterval);
    }

    playAnimation()
    {
        this.animation.play = (name) =>
        {
            const newAction = this.animation.actions[name]
            const oldAction = this.animation.actions.current

            if(newAction != oldAction){
                newAction.reset()
                newAction.play()
                newAction.crossFadeFrom(oldAction, 0.6)

                this.animation.actions.current = newAction
            }
        }

        // Debug
        if(this.debug.active)
        {
            const debugObject = {}
           
            for (let filename of this.animationSources) {
                const animationName = filename;
                const labelName = animationName.replace(/^avatarAnimation/, '');
                const functionName = `play${labelName}`;
                
                debugObject[functionName] = () => {
                  this.animation.play(animationName);
                };
                this.debugFolder.add(debugObject, functionName);
            }

            this.debugFolder
                .add(this.model.rotation, 'y')
                .name('avatarRotationY')
                .min(-5)
                .max(5)
                .step(0.001)
            
        }
    }

    setMouthAnimation()
    {
        this.model.animateMouth = (smileValue, openValue) =>
        {
        
            // Trova il mesh della testa
            // Tool per individuare i Morph Targets -> https://gltf-viewer.donmccurdy.com/
            this.model.headMesh = this.model.getObjectByName('Wolf3D_Avatar');
            if (!this.model.headMesh) {
                console.error('Head mesh not found.');
                return;
            }
        
            // Modifica i valori dei morph targets
            if (this.model.headMesh.morphTargetDictionary.hasOwnProperty('mouthSmile')) {
                this.model.headMesh.morphTargetInfluences[this.model.headMesh.morphTargetDictionary['mouthSmile']] = smileValue;
            }
            if (this.model.headMesh.morphTargetDictionary.hasOwnProperty('mouthOpen')) {
                this.model.headMesh.morphTargetInfluences[this.model.headMesh.morphTargetDictionary['mouthOpen']] = openValue;
            }

        }
    }

    listenerAnimations()
    {
        document.addEventListener("animation", (event) =>
        {
            this.animation.play(event.detail.animation)
            if(event.detail.animation != "avatarAnimationIdle" && event.detail.repeation != 0){
                setTimeout( () => {
                    this.animation.play("avatarAnimationIdle")
                }, event.detail.duration);
            }
        })

        window.addEventListener('message', (event) =>
        {
            if (event.data.event === 'animation') {
                this.animation.play(event.data.detail.animation)
            }
        });
          
    }

    listenerAudio()
    {
        window.addEventListener('message', function(event) {
            if (event.data.type === 'audio') {

                // Decodifica la stringa base64 in un oggetto ArrayBuffer
                const binaryString = atob(event.data.detail.message.audio);

                const binaryData = new Uint8Array(binaryString.length);
                for (let i = 0; i < binaryString.length; i++) {
                binaryData[i] = binaryString.charCodeAt(i);
                }
                const arrayBuffer = binaryData.buffer;
            
                // Crea un oggetto Blob dal buffer ArrayBuffer
                const blob = new Blob([arrayBuffer], { type: 'audio/mp3' });
            
                // Crea un URL per l'oggetto Blob
                const url = URL.createObjectURL(blob);
            
                // Crea un oggetto Audio e riproduci il file
                const audio = new Audio();
                audio.src = url;
            

                audio.addEventListener("loadedmetadata", () => {
                    const duration = audio.duration * 1000;
                    
                    // Triggero document con evento audio ascoltato in avatar.js
                    document.dispatchEvent(new CustomEvent("audio", { detail: { duration: duration, audio: audio}}));
                });
            }
        });

        document.addEventListener("audio", (event) => 
        {
            
            // Web Audio API setup
            const audioContext = new (window.AudioContext || window.webkitAudioContext)();
            const audioElement = event.detail.audio;
            const audioSourceNode = audioContext.createMediaElementSource(audioElement);
            const analyserNode = audioContext.createAnalyser();
            analyserNode.fftSize = 1024;
            const bufferLength = analyserNode.frequencyBinCount;
            const dataArray = new Uint8Array(bufferLength);
            audioSourceNode.connect(analyserNode);
            audioSourceNode.connect(audioContext.destination);
        
           /*  document.dispatchEvent(new CustomEvent("animation", { 
                detail: { animation: 'avatarAnimationHello', repeation: 1, duration: 1000, callback: 'avatarAnimationIdle'}
            })); */
            
            var requestId = undefined;
            function animate() {
                const experience = window.experience
                const model = experience.world.avatar;
                
                requestId = requestAnimationFrame(animate);
        
                // Aggiorna i morph targets in base all'audio
                updateMouthFromAudio(model);
            }
            animate(); 

            setTimeout(() => {
                animateStop()
                this.model.animateMouth(0, 0);
            }, (audioElement.duration * 1000));

            function animateStop(){
                if(requestId){
                    requestId = window.cancelAnimationFrame(requestId);
                    requestId = undefined;
                }
            }

            function updateMouthFromAudio(avatar) {
                analyserNode.getByteFrequencyData(dataArray);
        
                // Calcola il volume medio del segnale audio
                let sum = 0;
                for (let i = 0; i < bufferLength; i++) {
                    sum += dataArray[i];
                }
                const averageVolume = sum / bufferLength;
        
                // Mappa il volume medio a un valore tra 0 e 1 per l'apertura della bocca
                const scaleFactor = 4; // Aumenta questo valore per aumentare l'apertura della bocca
                const threshold = 0.12; // Aumenta questo valore per eliminare i valori molto bassi
                let openValue = Math.min(1, averageVolume / 255 * scaleFactor);
                openValue = openValue < threshold ? 0 : openValue;
        
                // Mappa la variazione del volume a un valore tra 0 e 1 per il sorriso
                const smileScaleFactor = 2.5; // Aumenta questo valore per aumentare l'intensità del sorriso
                const smileThreshold = 0.12; // Aumenta questo valore per eliminare i valori molto bassi
                let smileValue = Math.min(1, averageVolume / 255 * smileScaleFactor);
                smileValue = smileValue < smileThreshold ? 0 : smileValue;
        
                avatar.model.animateMouth(smileValue, openValue);
            }

            audioElement.play(); // Riproduce l'audio

            audioElement.onended = () => {
                
                // Seleziona l'elemento <iframe> tramite l'ID
                const iframe = document.getElementById('controller');

                // Verifica se l'elemento <iframe> esiste e ha l'attributo 'data-type' impostato su 'audio'
                if (iframe && iframe.getAttribute('data-type') === 'audio') {
                    
                    iframe.contentWindow.postMessage('startlistening', '*');

                } 

            }  

        });
    }

    listenerVideo()
    {
       
        window.addEventListener('message', function(event) {
            if (event.data.type === 'videoplay') {
              document.dispatchEvent(new CustomEvent("videoplay", { detail: { video: event.data.detail.message.video}}));
            } else if(event.data.type === 'videokill'){
                document.dispatchEvent(new CustomEvent("videokill"));
            } else if(event.data.type === 'videopause'){
                document.dispatchEvent(new CustomEvent("videopause"));
            } else if(event.data.type === 'videoresume'){
                document.dispatchEvent(new CustomEvent("videoresume"));
            }
        });

        document.addEventListener("videoplay", (event) => 
        {

            document.dispatchEvent(new CustomEvent("videokill", { }));


            const videoElement = document.createElement('video');
            videoElement.crossOrigin = "anonymous";
            videoElement.controls = true;
            videoElement.preload = 'metadata';
            videoElement.setAttribute('id', 'videoStream');
            videoElement.setAttribute('src', event.detail.video);
            videoElement.setAttribute('type', 'video/mp4');
            videoElement.setAttribute('controls', 'false');
            videoElement.setAttribute('crossorigin', 'anonymous');


            videoElement.controls = false;
            document.getElementById('wrapper').appendChild(videoElement)


            videoElement.addEventListener("loadedmetadata", () => {
            

                // Web Audio API setup
                const audioContext = new (window.AudioContext || window.webkitAudioContext)();
                const audioSourceNode = audioContext.createMediaElementSource(videoElement);
                const analyserNode = audioContext.createAnalyser();
                analyserNode.fftSize = 1024;
                const bufferLength = analyserNode.frequencyBinCount;
                const dataArray = new Uint8Array(bufferLength);
                audioSourceNode.connect(analyserNode);
                audioSourceNode.connect(audioContext.destination);

                var requestId = undefined;
                animate(); 
               

                function animate() {
                    const experience = window.experience
                    const model = experience.world.avatar;
                    
                    requestId = requestAnimationFrame(animate);
            
                    // Aggiorna i morph targets in base all'audio
                    updateMouthFromAudio(model);
                }
    
                function animateStop(){
                    if(requestId){
                        requestId = window.cancelAnimationFrame(requestId);
                        requestId = undefined;
                    }
                }
    
                function updateMouthFromAudio(avatar) {
                    analyserNode.getByteFrequencyData(dataArray);
            
                    // Calcola il volume medio del segnale audio
                    let sum = 0;
                    for (let i = 0; i < bufferLength; i++) {
                        sum += dataArray[i];
                    }
                    const averageVolume = sum / bufferLength;
            
                    // Mappa il volume medio a un valore tra 0 e 1 per l'apertura della bocca
                    const scaleFactor = 4; // Aumenta questo valore per aumentare l'apertura della bocca
                    const threshold = 0.12; // Aumenta questo valore per eliminare i valori molto bassi
                    let openValue = Math.min(1, averageVolume / 255 * scaleFactor);
                    openValue = openValue < threshold ? 0 : openValue;
            
                    // Mappa la variazione del volume a un valore tra 0 e 1 per il sorriso
                    const smileScaleFactor = 2.5; // Aumenta questo valore per aumentare l'intensità del sorriso
                    const smileThreshold = 0.12; // Aumenta questo valore per eliminare i valori molto bassi
                    let smileValue = Math.min(1, averageVolume / 255 * smileScaleFactor);
                    smileValue = smileValue < smileThreshold ? 0 : smileValue;
            
                    avatar.model.animateMouth(smileValue, openValue);
                }

                // Move scene
                gsap.to( videoElement, {
                    opacity: 1,
                    duration: 1.5,
                    ease: "power4.out",
                } );
                gsap.to( this.experience.canvas, {
                    width: '320px',
                    height: "180px",
                    duration: 2,
                    ease: "power4.out",
                    bottom: '8px',
                    right: '8px',
                    borderRadius: '8px',
                    onComplete: function(){ 
                        window.dispatchEvent(new Event('resize'));
                    },
                } );

               
                videoElement.play(); // Riproduce l'audio
                
    
                videoElement.onended = () => {

                    animateStop()
                    this.model.animateMouth(0, 0);
                    document.dispatchEvent(new CustomEvent("animation", { 
                        detail: { animation: 'avatarAnimationIdle', repeation: 1,}
                    }));
    
                    gsap.to( this.experience.canvas, {
                        delay: 0.2,
                        width: '100%',
                        height: "100%",
                        duration: 2,
                        ease: "power4.out",
                        borderRadius: "0",
                        bottom: '0',
                        right: '0',
                        onComplete: function(){ 
                            window.dispatchEvent(new Event('resize'));
                        },
                    } );
                    gsap.to( videoElement, {opacity: 0,duration: 2,} );

    
                }  
    
            
            });

         
        });


        document.addEventListener("videokill", (event) => 
        {
            if(document.getElementById('videoStream')){
                document.getElementById('videoStream').pause();
                this.model.animateMouth(0, 0);
                document.dispatchEvent(new CustomEvent("animation", { 
                    detail: { animation: 'avatarAnimationIdle', repeation: 1,}
                }));
                gsap.to( this.experience.canvas, {
                    delay: 0.2,
                    width: '100%',
                    height: "100%",
                    duration: 3,
                    ease: "power4.out",
                    borderRadius: "0",
                    bottom: '0',
                    right: '0',
                    onEnded: function(){ 
                        window.dispatchEvent(new Event('resize'));
                    },
                } );
                gsap.to( document.getElementById('videoStream'), {
                    opacity: 0,
                    duration: 2, 
                    onEnded: function(){ 
                        document.getElementById('wrapper').removeChild(document.getElementById('videoStream'));
                    }
                } );
                
            }
        });

        document.addEventListener("videopause", (event) => 
        {
            if(document.getElementById('videoStream')){
                document.getElementById('videoStream').pause();
            }
        });

        document.addEventListener("videoresume", (event) => 
        {
            if(document.getElementById('videoStream')){
                document.getElementById('videoStream').play();
            }
        });
    }


    update()
    {
        this.animation.mixer.update(this.experience.time.delta * 0.001)
    }
}