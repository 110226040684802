import Experience from "../Experience";
import Environment from './Environment';
import Avatar from './Avatar';
import Floor from "./Floor";

export default class World{
    constructor(){
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources


        // Wait for resources
        this.resources.on('ready', () => {
            // Setup
            this.floor = new Floor()
            this.avatar = new Avatar()
            this.environment = new Environment()
        })

    }

    update(){
        if(this.avatar){
            this.avatar.update()
        }
        
    }
}