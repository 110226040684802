

export default class OnboardingParams
{
    constructor(){


        const params = new URLSearchParams(window.location.search);

        const paramsObj = Array.from(params.keys()).reduce(
          (acc, val) => ({ ...acc, [val]: params.get(val) }),
          {}
        )
        
        return paramsObj;

    }

}