
export default class OnboardingLoader
{
    constructor(string){

        this.string = string

        // Setup
        var loaderWrapper = document.createElement('div');
        loaderWrapper.setAttribute("id", "loaderWrapper");
        document.body.appendChild(loaderWrapper);

        var loaderTitle = document.createElement("H3");
        loaderTitle.innerHTML = this.string;
        loaderWrapper.appendChild(loaderTitle);

        var loaderImage = document.createElement("img");
        loaderImage.src = "/image/loader.svg";
        loaderWrapper.appendChild(loaderImage);


    }

    

   

   
}