import * as THREE from 'three'
import Experience from "../Experience";

export default class Video{

    constructor()
    {

        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources

        this.listenerVideo();
    }

    listenerVideo()
    {
        document.addEventListener("video", (event) => {

            const videoUrl = event.detail.video;

            // Crea un piano per posizionare l'video al di sotto della scena
            const geometry = new THREE.PlaneGeometry(16, 9);
            const material = new THREE.MeshBasicMaterial({ color: 0xffffff });
            const plane = new THREE.Mesh(geometry, material);
            plane.position.y = -5; // posizionato 5 unità sotto l'origine (0,0,0)
            plane.rotation.x = -Math.PI / 2;
          
            // Aggiungi il piano alla scena
            this.scene.add(plane);

            // Crea un video e imposta l'attributo src all'URL specificato
            const video = document.createElement('video');
            video.src = videoUrl;
          
            // Imposta la posizione dell'video rispetto al piano
            video.style.position = 'absolute';
            video.style.width = "calc(100% - 1.5em)"
            video.style.height = 'auto';
            video.style.bottom = '0'; // fa partire l'video dal basso
            video.style.left = '50%';
            video.style.transform = "translate(-50%,50%)"
            video.style.opacity = '0';
            video.style.zIndex = "20";
            video.style.borderRadius = "1em";
            
            // Aggiungi l'video al documento e al piano
            document.body.appendChild(video);
            plane.element = video;

            // Crea layer scuro sotto il video
            const layer = document.createElement('div');
            layer.style.position = 'absolute';
            layer.style.width = '100%';
            layer.style.height = '100%';
            layer.style.top = '0'; 
            layer.style.left = '0';
            layer.style.opacity = '0';
            layer.style.backgroundColor = "#000000";
            layer.style.transition = 'all 1s';
            layer.style.zIndex = "10";


            // Aggiungi l'video al documento e al piano
            document.body.appendChild(layer);

      
            
            setTimeout(function() {
                showvideo(video);
                showlayer(layer);
                const videoDuration = (video.duration * 1000) + 2000;

                setTimeout(function() {
                    hidevideo(video);
                    hidelayer(layer);
                }, videoDuration); 
            }, 500); 
        });

       
    }

    
}


function showvideo(video) {
    // Imposta la posizione di partenza dell'video fuori dallo schermo
    video.style.bottom = '0';
    video.style.opacity = '0';
  
    // Mostra l'video gradualmente con una transizione
    video.style.transition = 'all 1s';
    video.style.bottom = '50%';
    video.style.opacity = '1';
  
    // Disabilita lo scorrimento della pagina mentre l'video è visibile
    document.body.style.overflow = 'hidden';
    
    setTimeout(function() {
      video.play();
    }, 1000);
}

function hidevideo(video, layer) {

    video.pause();

    // Nasconde gradualmente l'video con una transizione
    video.style.transition = 'all 1s';
    video.style.bottom = '0';
    video.style.opacity = '0';

    // Rimuovi l'video dal documento dopo la transizione
    setTimeout(function() {
        document.body.removeChild(video);
    }, 1000);

    // Abilita lo scorrimento della pagina dopo aver rimosso l'video
    document.body.style.overflow = 'auto';
}
  
function showlayer(layer) {
    layer.style.opacity = '0';

    // Mostra l'video gradualmente con una transizione
    layer.style.transition = 'all 1s';
    layer.style.opacity = '0.6';

    // Disabilita lo scorrimento della pagina mentre l'video è visibile
    document.body.style.overflow = 'hidden';
    
}

function hidelayer(layer) {

    layer.style.transition = 'all 1s';
    layer.style.opacity = '0';

    // Rimuovi l'video dal documento dopo la transizione
    setTimeout(function() {
        document.body.removeChild(layer);
    }, 1000);

    // Abilita lo scorrimento della pagina dopo aver rimosso l'video
    document.body.style.overflow = 'auto';
}