
export default class FloorTexture
{
    constructor(){

        // Setup

        var floorTextureWrapper = document.createElement('div');
        floorTextureWrapper.setAttribute("id", "floorTextureWrapper");
        floorTextureWrapper.classList.add("form-wrapper")
        document.body.appendChild(floorTextureWrapper);

        var floorTextureTitle = document.createElement("H2");
        floorTextureTitle.innerHTML = "Scegli ambientazione";
        floorTextureWrapper.appendChild(floorTextureTitle);

        var floorTextureText = document.createElement("p");
        floorTextureText.innerHTML = "floorTexturepa ambientale utilizzata per creare effetti realistici d'illuminazione e riflessi.";
        floorTextureWrapper.appendChild(floorTextureText);

        var floorTextureForm = document.createElement("form");
        floorTextureForm.addEventListener('submit', event => {
            event.preventDefault();
            var checkedBoxes = document.querySelector('input:checked');
            window.dispatchEvent(
                new CustomEvent("onboardingFloorEnd", {
                  detail: checkedBoxes.value,
                })
            );
            document.body.removeChild(floorTextureWrapper);

        });

        // Create input 1
        var floorTextureLabel1 = document.createElement("label");
        var floorTextureInput1 = document.createElement("input");
        var floorTextureTitle1 = document.createElement("h5");
        floorTextureTitle1.innerHTML = "Dirt"
        floorTextureLabel1.appendChild(floorTextureTitle1);
        floorTextureInput1.type = "checkbox";
        floorTextureInput1.name = "check[]";
        floorTextureInput1.value = "dirt";
        floorTextureInput1.checked = true;
        floorTextureInput1.classList.add("floorTextureInput1")
        floorTextureInput1.onclick = function () { onlyOne(this);};
        floorTextureLabel1.appendChild(floorTextureInput1);
        floorTextureForm.appendChild(floorTextureLabel1);

        // Create input 2
        var floorTextureLabel2 = document.createElement("label");
        var floorTextureInput2 = document.createElement("input");
        var floorTextureTitle2 = document.createElement("h5");
        floorTextureTitle2.innerHTML = "Stone"
        floorTextureLabel2.appendChild(floorTextureTitle2);
        floorTextureInput2.type = "checkbox";
        floorTextureInput2.name = "check[]";
        floorTextureInput2.value = "stone";
        floorTextureInput2.classList.add("floorTextureInput2")
        floorTextureInput2.onclick = function () { onlyOne(this);};
        floorTextureLabel2.appendChild(floorTextureInput2);
        floorTextureForm.appendChild(floorTextureLabel2);

        // Create input 3
        var floorTextureLabel3 = document.createElement("label");
        var floorTextureInput3 = document.createElement("input");
        var floorTextureTitle3 = document.createElement("h5");
        floorTextureTitle3.innerHTML = "Marble"
        floorTextureLabel3.appendChild(floorTextureTitle3);
        floorTextureInput3.type = "checkbox";
        floorTextureInput3.name = "check[]";
        floorTextureInput3.value = "marble";
        floorTextureInput3.classList.add("floorTextureInput3")
        floorTextureInput3.onclick = function () { onlyOne(this);};
        floorTextureLabel3.appendChild(floorTextureInput3);
        floorTextureForm.appendChild(floorTextureLabel3);

        // Create input 4
        var floorTextureLabel4 = document.createElement("label");
        var floorTextureInput4 = document.createElement("input");
        var floorTextureTitle4 = document.createElement("h5");
        floorTextureTitle4.innerHTML = "None"
        floorTextureLabel4.appendChild(floorTextureTitle4);
        floorTextureInput4.type = "checkbox";
        floorTextureInput4.name = "check[]";
        floorTextureInput4.value = "";
        floorTextureInput4.classList.add("floorTextureInput4")
        floorTextureInput4.onclick = function () { onlyOne(this);};
        floorTextureLabel4.appendChild(floorTextureInput4);
        floorTextureForm.appendChild(floorTextureLabel4);

        // Create button
        var floorTextureButton = document.createElement("button");
        floorTextureButton.type = "submit";
        floorTextureButton.innerHTML = "Avanti";
        floorTextureForm.appendChild(floorTextureButton);

        floorTextureWrapper.appendChild(floorTextureForm);

        function onlyOne(checkbox) {
            var checkboxes = document.getElementsByName('check[]')
            checkboxes.forEach((item) => {
                if (item !== checkbox) item.checked = false
            })
        }

        
    }

  
   
}