export default class SessionToken
{
    constructor(){

        // Esempio di utilizzo
        var currentURL = window.location.href;  // Ottiene l'URL corrente
        var updatedURL = addSessionIDToURL(currentURL);
          
    }

  
   
}



function generateSessionID() {
    // Genera un timestamp univoco
    var timestamp = Date.now().toString();
  
    // Genera un numero casuale
    var randomNum = Math.floor(Math.random() * 10000).toString();
  
    // Combina il timestamp e il numero casuale per creare l'ID di sessione
    var sessionID = timestamp + randomNum;
  
    return sessionID;
}

function addSessionIDToURL() {
    // Genera un ID di sessione solo se il parametro "ID" non è già presente nell'URL
    if (!getParameterByName("ID")) {
        // Genera l'ID di sessione
        var sessionID = generateSessionID();
    
        // Verifica se l'URL ha già dei parametri
        var separator = window.location.href.includes("?") ? "&" : "?";
    
        // Aggiunge l'ID di sessione come parametro "ID" all'URL corrente
        var updatedURL = window.location.href + separator + "ID=" + sessionID;
    
        // Modifica l'URL corrente senza ricaricare la pagina
        history.replaceState(null, "", updatedURL);
    }
}
  

// Funzione per ottenere il valore di un parametro dall'URL
function getParameterByName(name) {
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(window.location.href);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));

}