import Controllers from './Controllers/Controllers';
import EnvironmentMapForm from './Onboarding/EnvironmentMap/EnvironmentMap';
import Experience from './Experience/Experience';
import Loader from './Onboarding/Loader/OnboardingLoader';
import ReadyPlayerMe from './Onboarding/ReadyPlayerMe/ReadyPlayerMe';
import OnboardingController from './Onboarding/Controller/onboardingController';
import FloorTexture from './Onboarding/FloorTexture/FloorTexture';
import OnboardingParams from './Onboarding/OnboardingParams';
import SessionToken from './Utils/SessionToken';

//const CONTROLLER_TOKEN = '';
const sessionToken = new SessionToken();


const onboardingParams = new OnboardingParams();

// /?avatar=https://api.readyplayer.me/v1/avatars/645a78116977bf73b0dbf27a.glb&env=black&controller=audio&floor=#debug

var readyplayerModel =  (onboardingParams['avatar']) ? onboardingParams['avatar'] : '';
var environmentMap = (onboardingParams['env']) ? onboardingParams['env'] : '';
var floorTexture = (onboardingParams['floor']) ? onboardingParams['floor'] : '';
var controller = (onboardingParams['controller']) ? onboardingParams['controller'] : '';


const canvas = document.querySelector('canvas.webgl');
const readyplayerFrame = document.getElementById('readyPlayerMeIframe')


if(floorTexture, environmentMap, floorTexture, controller){
    
    const loader = new Loader("Stiamo caricando la tua scena...");
    const experience = new Experience(canvas, readyplayerModel, environmentMap, floorTexture, controller);
    window.addEventListener("experienceReady", (e) => {
        document.body.removeChild(document.getElementById("readyPlayerMeIframe"));
        canvas.classList.remove('hidden');
        //const controllers = new Controllers(controller);
    }); 

} else {

const readyplayer = new ReadyPlayerMe(readyplayerFrame, readyplayerModel);

readyplayerFrame.addEventListener("onboardingRpmEnd", (e) => {
    readyplayerModel = e.detail
    const environmentMapForm = new EnvironmentMapForm();
});  

window.addEventListener("onboardingEnvEnd", (e) => {
    environmentMap = e.detail
    const onboardingFloorTexture = new FloorTexture();
}); 

window.addEventListener("onboardingFloorEnd", (e) => {
    floorTexture = e.detail
    const onboardingController = new OnboardingController();
}); 


window.addEventListener("onboardingReady", (e) => {
    controller = e.detail
    document.body.removeChild(document.getElementById("controllerWrapper"));

    const loader = new Loader("Stiamo caricando la tua scena...");
    canvas.classList.remove('hidden');
    const experience = new Experience(canvas, readyplayerModel, environmentMap, floorTexture, controller);
}); 

/* window.addEventListener("experienceReady", (e) => {
    const controllers = new Controllers(controller);
});  */


}




