import EventEmitter from './EventEmitter'
import Experience from "../Experience";


export default class Sizes extends EventEmitter
{
    constructor(controller)
    {

        super()

        this.experience = new Experience()

        // Setup
        this.width = window.innerWidth
        this.height = (controller === 'button') ? vh(70) : window.innerHeight
        this.pixelRatio = Math.min(window.devicePixelRatio, 2)

        function vh(percent) {
            var h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
            return (percent * h) / 100;
        }

        // Resize event
        window.addEventListener('resize', () =>
        {
            if(document.querySelector('#videoStream')){
                this.width = this.experience.canvas.offsetWidth
                this.height = this.experience.canvas.offsetHeight
            } else {
               
                this.width = window.innerWidth
                this.height = window.innerHeight
    
            }
            this.pixelRatio = Math.min(window.devicePixelRatio, 2)
            this.trigger('resize');
        })
    }
}