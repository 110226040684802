import Experience from "../Experience";


export default class Loader{
    constructor(){
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources

        // Wait for resources
        this.resources.on('ready', () => {
            this.removeLoader();
            window.dispatchEvent(
                new CustomEvent("experienceReady", {})
            );
        })

    }

    removeLoader(){
        const loaderWrapper = document.getElementById('loaderWrapper')
        document.body.removeChild(loaderWrapper);
    }
}