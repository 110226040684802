export default class OnboardingController
{
    constructor(){

       // Setup
       const urlParams = new URLSearchParams(window.location.search);
       const sessionToken = urlParams.get('ID');

        // Setup

        var controllerWrapper = document.createElement('div');
        controllerWrapper.setAttribute("id", "controllerWrapper");
        controllerWrapper.classList.add("form-wrapper")
        document.body.appendChild(controllerWrapper);

        var controllerTitle = document.createElement("H2");
        controllerTitle.innerHTML = "Scegli controller";
        controllerWrapper.appendChild(controllerTitle);

        var controllerText = document.createElement("p");
        controllerText.innerHTML = "Lorem ipsum.";
        controllerWrapper.appendChild(controllerText);

        var controllerForm = document.createElement("form");
        controllerForm.addEventListener('submit', event => {
            event.preventDefault();
            var checkedBoxes = document.querySelector('input:checked');
            controllerWrapper.style.display = "none"

            if(checkedBoxes.value == 'audio'){
              showVolumeSetting()
            } else if (checkedBoxes.value == 'tablet'){
              showTabletSetting()
            } else {
              window.dispatchEvent(
                new CustomEvent("onboardingReady", {
                  detail: checkedBoxes.value,
                })
              );
            }
            
            

        });

        window.addEventListener("onboardingControllerVolume", (e) => {
            var volumeWrapper =  document.querySelector('#volumeWrapper');
            document.body.removeChild(volumeWrapper);

            if(window.controllerVoice){
              avatarNameSetting();
              return;
            }

            if(window.controllerVolume){
              showVolumeSetting();
              return;
            }

            //avatarNameSetting()

           /*  var checkedBoxes = document.querySelector('input:checked');
            window.dispatchEvent(
                new CustomEvent("onboardingReady", {
                  detail: checkedBoxes.value,
                })
            ); */

        }); 

        // Create input 1
        var controllerLabel1 = document.createElement("label");
        var controllerInput1 = document.createElement("input");
        var controllerTitle1 = document.createElement("h5");
        controllerTitle1.innerHTML = "Button"
        controllerLabel1.appendChild(controllerTitle1);
        controllerInput1.type = "checkbox";
        controllerInput1.name = "check[]";
        controllerInput1.value = "button";
        controllerInput1.classList.add("controllerInput1")
        controllerInput1.onclick = function () { onlyOne(this);};
        controllerLabel1.appendChild(controllerInput1);
        controllerForm.appendChild(controllerLabel1);

        // Create input 2
        var controllerLabel2 = document.createElement("label");
        var controllerInput2 = document.createElement("input");
        var controllerTitle2 = document.createElement("h5");
        controllerTitle2.innerHTML = "Speech"
        controllerLabel2.appendChild(controllerTitle2);
        controllerInput2.type = "checkbox";
        controllerInput2.name = "check[]";
        controllerInput2.value = "audio";
        controllerInput2.classList.add("controllerInput2")
        controllerInput2.onclick = function () { onlyOne(this);};
        controllerLabel2.appendChild(controllerInput2);
        controllerForm.appendChild(controllerLabel2);

        // Create input 3
        var controllerLabel3 = document.createElement("label");
        var controllerInput3 = document.createElement("input");
        var controllerTitle3 = document.createElement("h5");
        controllerTitle3.innerHTML = "Tablet"
        controllerLabel3.appendChild(controllerTitle3);
        controllerInput3.type = "checkbox";
        controllerInput3.name = "check[]";
        controllerInput3.value = "tablet";
        controllerInput3.classList.add("controllerInput3")
        controllerInput3.onclick = function () { onlyOne(this);};
        controllerLabel3.appendChild(controllerInput3);
        controllerForm.appendChild(controllerLabel3);

        // Create input 4
        var controllerLabel4 = document.createElement("label");
        var controllerInput4 = document.createElement("input");
        var controllerTitle4 = document.createElement("h5");
        controllerTitle4.innerHTML = "None"
        controllerLabel4.appendChild(controllerTitle4);
        controllerInput4.type = "checkbox";
        controllerInput4.name = "check[]";
        controllerInput4.value = "";
        controllerInput4.checked = true;
        controllerInput4.classList.add("controllerInput4")
        controllerInput4.onclick = function () { onlyOne(this);};
        controllerLabel4.appendChild(controllerInput4);
        controllerForm.appendChild(controllerLabel4);

        // Create button
        var controllerButton = document.createElement("button");
        controllerButton.type = "submit";
        controllerButton.innerHTML = "Avanti";
        controllerForm.appendChild(controllerButton);

        controllerWrapper.appendChild(controllerForm);

        function onlyOne(checkbox) {
            var checkboxes = document.getElementsByName('check[]')
            checkboxes.forEach((item) => {
                if (item !== checkbox) item.checked = false
            })
        }

        function showTabletSetting() {
          var tabletWrapper = document.createElement('div');
          tabletWrapper.setAttribute("id", "tabletWrapper");
          tabletWrapper.classList.add("form-wrapper")
          document.body.appendChild(tabletWrapper);

          var tabletTitle = document.createElement("H2");
          tabletTitle.innerHTML = "Controller tablet";
          tabletWrapper.appendChild(tabletTitle);

          var tabletText = document.createElement("p");
          tabletText.innerHTML = "Apri questa schermata su un dispositivo o schermo esterno per inviare comandi vocali e ricevere sottotitoli e qr code.";
          tabletWrapper.appendChild(tabletText);

         
          var tabletUrl = document.createElement("textarea");
          tabletUrl.setAttribute("class", "js-copytextarea");
          tabletUrl.setAttribute("readonly", "true");
          tabletUrl.innerHTML = "https://vir-host.baasbox.dev/controllers/tablet/index.html?ID="+sessionToken+"&controller=button";
          tabletWrapper.appendChild(tabletUrl);


          var tabletUrlActions = document.createElement("div");
          tabletUrlActions.setAttribute("class", "tablet-url-actions");

          var tabletUrlOpen = document.createElement("a");
          tabletUrlOpen.setAttribute("href", "https://vir-host.baasbox.dev/controllers/tablet/index.html?ID="+sessionToken+"&controller=button");
          tabletUrlOpen.setAttribute("target", "_blank");
          tabletUrlOpen.innerHTML = "Open link"
          tabletUrlActions.appendChild(tabletUrlOpen);

          var tabletUrlCopy = document.createElement("button");
          tabletUrlCopy.setAttribute("class", "js-textareacopybtn");
          tabletUrlCopy.innerHTML = "Copy link"
          tabletUrlCopy.onclick = function () {
            var copyTextarea = document.querySelector('.js-copytextarea');
            copyTextarea.focus();
            copyTextarea.select();

            try {
              var successful = document.execCommand('copy');
              var msg = successful ? 'successful' : 'unsuccessful';
              console.log('Copying text command was ' + msg);
            } catch (err) {
              console.log('Oops, unable to copy');
            }
          };
          tabletUrlActions.appendChild(tabletUrlCopy);

          tabletWrapper.appendChild(tabletUrlActions);



          // Create button
          var tabletButton = document.createElement("button");
          tabletButton.setAttribute("id", "tabletButton");
          tabletButton.innerHTML = "Avanti";
          tabletWrapper.appendChild(tabletButton);
          tabletButton.onclick = function(){
            document.body.removeChild(tabletWrapper);
            window.dispatchEvent(
              new CustomEvent("onboardingReady", {
                detail: "tablet",
              })
            );
          }
        }


        function showVolumeSetting() {

            var volumeWrapper = document.createElement('div');
            volumeWrapper.setAttribute("id", "volumeWrapper");
            volumeWrapper.classList.add("form-wrapper")
            document.body.appendChild(volumeWrapper);

            var volumeTitle = document.createElement("H2");
            if(window.controllerVolume){
              volumeTitle.innerHTML = "Volume voce";
            } else {
              volumeTitle.innerHTML = "Volume ambientale";
            }
            volumeWrapper.appendChild(volumeTitle);

            var volumeText = document.createElement("p");
            if(window.controllerVolume){
              volumeText.innerHTML = "Serve per definire il volume medio della voce. Parla in modo continuativo per 10 secondi.";
            } else {
              volumeText.innerHTML = "Serve per definire i momenti di silenzio nella registrazione. Non parlare per 10 secondi.";
            }
            volumeWrapper.appendChild(volumeText);

            // Create button
            var volumeButton = document.createElement("button");
            volumeButton.setAttribute("id", "volumeButton");
            volumeButton.innerHTML = "Analizza";
            volumeWrapper.appendChild(volumeButton);
            volumeButton.onclick = function(){
              volumeButton.disabled = true;
              volumeButton.innerHTML = "In ascolto...";
              registraAudio(10000)
              .then((audioBlob) => {
                  window.dispatchEvent(new CustomEvent("onboardingControllerVolume", {}));
              })
              .catch((error) => {
                  console.error('Errore durante la registrazione audio:', error);
              });
            }
        }


        function avatarNameSetting() {
          var avatarNameWrapper = document.createElement('div');
          avatarNameWrapper.setAttribute("id", "avatarNameWrapper");
          avatarNameWrapper.classList.add("form-wrapper")
          document.body.appendChild(avatarNameWrapper);

          var avatarNameTitle = document.createElement("H2");
          avatarNameTitle.innerHTML = "Nome assistente vocale";
          avatarNameWrapper.appendChild(avatarNameTitle);

          var avatarNameText = document.createElement("p");
          avatarNameText.innerHTML = "Inserisci il nome proprio associato all'assistente, verrà utilizzato per attivarsi quando pronunciato all'interno di qualsiasi frase.";
          avatarNameWrapper.appendChild(avatarNameText);

          var avatarNameForm = document.createElement("form");
          avatarNameForm.addEventListener('submit', event => {
            event.preventDefault();

            var avatarName = document.querySelector('input[name="avatar-name"]').value;
            document.body.removeChild(avatarNameWrapper);

            window.sceneAvatarName = avatarName;

            window.dispatchEvent(
              new CustomEvent("onboardingReady", {
                detail: 'audio',
              })
            );
            
          });

          // Create input 1
          var avatarNameInput1 = document.createElement("input");
          var avatarNameTitle1 = document.createElement("h5");
          avatarNameTitle1.innerHTML = "Button"
          avatarNameInput1.type = "text";
          avatarNameInput1.name = "avatar-name";
          avatarNameInput1.value = "";
          avatarNameInput1.placeholder = "Inserisci nome...";
          avatarNameInput1.classList.add("avatarNameInput1")
          avatarNameForm.appendChild(avatarNameInput1);

          // Create button
          var avatarNameButton = document.createElement("button");
          avatarNameButton.type = "submit";
          avatarNameButton.innerHTML = "Avanti";
          avatarNameForm.appendChild(avatarNameButton);

          avatarNameWrapper.appendChild(avatarNameForm);
          
        }

        
    }

  
   
}



  // Funzione per calcolare il valore medio del volume in dB
  function calcolaValoreMedioVolume(stream) {
    const audioContext = new AudioContext();
    const sourceNode = audioContext.createMediaStreamSource(stream);
    const scriptNode = audioContext.createScriptProcessor(4096, 1, 1); // Numero di campioni da elaborare

    let sommaQuadrati = 0;
    let campioni = 0;

    scriptNode.onaudioprocess = (event) => {
      const inputBuffer = event.inputBuffer;
      const inputData = inputBuffer.getChannelData(0);

      for (let i = 0; i < inputData.length; i++) {
        sommaQuadrati += inputData[i] * inputData[i];
      }

      campioni += inputData.length;
    };

    sourceNode.connect(scriptNode);
    scriptNode.connect(audioContext.destination);

    // Termina il calcolo e visualizza il valore medio del volume in dB
    function visualizzaValoreMedio() {
      scriptNode.disconnect();
      const valoreRMS = Math.sqrt(sommaQuadrati / campioni);
      const valoreDB = 20 * Math.log10(valoreRMS);
      const valoreNormalizzato = parseFloat(Math.pow(10, valoreDB / 20).toFixed(4)); // Normalizza il valore tra infinito e 0.0001

      if(window.controllerVolume){
        console.log('Valore medio della voce:', valoreNormalizzato);
        window.controllerVoice = valoreNormalizzato;
      } else {
        console.log('Valore medio del volume ambientale:', valoreNormalizzato);
        window.controllerVolume = valoreNormalizzato;
      }

      
      
    }

    return visualizzaValoreMedio;
  }

  // Funzione per registrare l'audio per un periodo di tempo specifico
  function registraAudio(duration) {
    return new Promise((resolve, reject) => {
      navigator.mediaDevices.getUserMedia({ audio: true })
        .then((stream) => {
          const visualizzaValoreMedio = calcolaValoreMedioVolume(stream); // Calcola il valore medio del volume ambientale durante la registrazione

          const audioContext = new AudioContext();
          const sourceNode = audioContext.createMediaStreamSource(stream);
          const scriptNode = audioContext.createScriptProcessor(4096, 1, 1); // Numero di campioni da elaborare
          const recorder = new MediaRecorder(stream);
          const chunks = [];

          scriptNode.onaudioprocess = (event) => {
            chunks.push(event.inputBuffer);
          };

          recorder.onstop = () => {
            const audioBlob = new Blob(chunks, { type: 'audio/wav' });
            resolve(audioBlob);
          };

          sourceNode.connect(scriptNode);
          scriptNode.connect(audioContext.destination);

          recorder.start();
          setTimeout(() => {
            recorder.stop();
            visualizzaValoreMedio(); // Visualizza il valore medio del volume alla fine della registrazione
          }, duration);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
