
export default class ReadyPlayerMe
{
    constructor(readyplayerFrame, readyplayerModel){

        // Setup
        const subdomain = 'mexedia'; // Replace with your custom subdomain
        const frame = readyplayerFrame;
        frame.src = `https://${subdomain}.readyplayer.me/it/avatar?clearCache&frameApi`;

        this.displayIframe(frame)

        window.addEventListener('message', subscribe);
        document.addEventListener('message', subscribe);

        function subscribe(event) {
            const json = parse(event);

            if (json?.source !== 'readyplayerme') {
                return;
            }

            // Susbribe to all events sent from Ready Player Me once frame is ready
            if (json.eventName === 'v1.frame.ready') {
                frame.contentWindow.postMessage(
                    JSON.stringify({
                        target: 'readyplayerme',
                        type: 'subscribe',
                        eventName: 'v1.**'
                    }),
                    '*'
                );
            }

            // Get avatar GLB URL
            if (json.eventName === 'v1.avatar.exported') {
                frame.hidden = true;
                readyplayerModel = "https://api.readyplayer.me/v1/avatars/"+json.data.url.split('https://models.readyplayer.me/')[1];
                //return readyplayerModel;
                document.body.removeChild(frame)
                frame.dispatchEvent(
                    new CustomEvent("onboardingRpmEnd", {
                      detail: readyplayerModel,
                    })
                );
            }

            // Get user id
            if (json.eventName === 'v1.user.set') {
                //console.log(`User with id ${json.data.id} set: ${JSON.stringify(json)}`);
            }
        }

        function parse(event) {
            try {
                return JSON.parse(event.data);
            } catch (error) {
                return null;
            }
        }

        
    }

    displayIframe(frame)
    {
        frame.hidden = false;
    }

   
   
}